import { Grid, List, TextField, Typography } from '@material-ui/core'
import { Link } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'

import Header from '../components/Header'
import SearchCard from '../components/SearchCard'
import Seo from '../components/Seo'

import { useStyles } from '../styles/pages/search'

const SearchPage = (props) => {
  const classes = useStyles()

  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)

  useEffect(() => {
    const queryData = props.location.search.split('=')[1]
    setQuery(queryData)
  }, [props.location.search])

  const index = queryData.localSearchPages.index
  const store = queryData.localSearchPages.store

  const [query, setQuery] = useState('')
  const results = useFlexSearch(query, index, store)

  return (
    <>
      <Header />
      <Seo title="Search" />
      <Grid className={classes.fixedContainer}>
        <div className="row bg-mid-hero">
          <div class="col align-self-center">
            <h3
              className="title-post"
              dangerouslySetInnerHTML={{ __html: 'Guide Search' }}
            />
          </div>
        </div>
        <Typography className={classes.lookingProvider}>
          If you are looking to search for a provider or treatment center near
          you, please click
          <Link
            className={classes.lookingProviderLink}
            role="button"
            to={`/agencies/`}
            style={{
              textDecoration: 'none',
            }}
          >
            here
          </Link>
        </Typography>
        <Grid className={classes.top}>
          <TextField
            className={classes.textField}
            onChange={(value) => setQuery(value.target.value)}
            placeholder={'Search'}
            variant="outlined"
            value={query}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
        <Typography className={classes.titleList}>Results</Typography>
        <List className={classes.list}>
          {results && results.length ? (
            results.map((result, index) => (
              <SearchCard key={index} data={result} />
            ))
          ) : (
            <>
              <Typography className={classes.noResults}>
                {query && query.length
                  ? `No results found for "${query}"`
                  : 'No results found'}
              </Typography>
              <Typography className={classes.noResultsText}>
                Check your spelling and try again, or search by a different
                keyword.
              </Typography>
            </>
          )}
        </List>
      </Grid>
    </>
  )
}

export default SearchPage
