import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({
  fixedContainer: {
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'fixed',
    width: '100%',
  },
  italicText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontStyle: 'italic',
    margin: '1vh 0',
  },
  list: {
    alignItems: 'center',
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '80px',
    position: 'relative',
  },
  lookingProvider: {
    color: colors.textGrey,
    fontFamily: 'Roboto',
    fontSize: '0.8em',
    letterSpacing: '0',
    margin: '4% 5% 0', 
    textAlign: 'left',
  },
  lookingProviderLink: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',
    letterSpacing: '0',
    marginLeft: '5px', 
    textAlign: 'left',
  },  
  noResults: {
    color: colors.textGrey,
    fontFamily: 'Mont-Bold',
    fontSize: '1.1em',
    letterSpacing: '0',
    margin: '5vh 4% 1vh', 
    textAlign: 'center',
  },
  noResultsText: {
    color: colors.textGrey,
    fontFamily: 'Roboto',
    fontSize: '0.9em',
    letterSpacing: '0',
    margin: '0 8%', 
    textAlign: 'center',
  },
  notchedOutline: {
    border: `0px solid ${colors.lightBlue}`,
  },  
  root: {
    alignItems: 'center',
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  textField: {
    border: `2px solid ${colors.lightBlue}`,
    borderRadius: '5px',
    backgroundColor: colors.white,
    fontWeight: 'bold',
    margin: '0 0 2vh',
    width: '100%',
  },
  title: {
    color: colors.brandBlue,
    fontSize: '1.2em',
    fontFamily: 'Mont-Heavy',
    letterSpacing: '0',
    textTransform: 'uppercase',
  },
  titleList: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',
    fontSize: '1em',
    letterSpacing: '0',
    margin: '1vh 4% 2vh',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '60px',
    padding: '2vh 4% 1vh',
    width: '100%',
  },
}))
