import { Grid, Typography } from '@material-ui/core'
import { Link } from '@reach/router'
import React from 'react'

import { useStyles } from './styles'

const SearchCard = (data) => {
  const classes = useStyles()

  const result = data.data

  return (
    <Link
      to={`${result.uri}`}
      style={{ textDecoration: 'none' }}
      className={classes.listItem}
      key={data.index}
      role="button"
    >
      <Grid className={classes.top}>
        <Typography className={classes.title}>{result.title}</Typography>
      </Grid>
    </Link>
  )
}

export { SearchCard }
