import { makeStyles } from '@material-ui/core/styles'

import {colors} from '../../theme'

export const useStyles = makeStyles(_theme => ({
  listItem: {
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: '5px',
    boxShadow: '3px 0px 10px 0px rgba(25,65,117,0.17)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 1vw 2vh',
    padding: '2vh 5vw 2vh 5vw',
    width: '92%',
  },
  title: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',
    fontSize: '1.1em',
    letterSpacing: '0',
    textTransform: 'uppercase',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1vh 0',
  },
}))
